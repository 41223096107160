import React, { useEffect } from 'react';

import classes from './Welcome.module.css';
import gsap from 'gsap';

const Welcome = (props) => {

    useEffect(() => {

        gsap.to("#welcomeText", {
            opacity: 0.0, duration: 0.0, onComplete: () => {
                gsap.to("#welcomeText", {
                    opacity: 1.0, duration: 1.0, stagger: 1.5
                });
            }
        });

    }, []);

    return (
        <div id="konami" className={classes.Background}>
            <div className={classes.SpinnerContainer}>
                <div className={classes.Spinner}>
                    <div className={classes.Cube1}></div>
                    <div className={classes.Cube2}></div>
                </div>
            </div>
            <div className={classes.Welcome}>
                <p id="welcomeText" className={classes.Text}>Welcome, human!</p>
                <p id="welcomeText" className={classes.Text}><span style={{ fontSize: '3vw' }}>Just one sec, I wasn't expecting guests...</span></p>
            </div>
        </div>
    )
}

export default Welcome;