import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Bsod from './components/BSOD/BSOD';
import Welcome from './components/Welcome/Welcome';

import './App.css';

// import Home from './containers/Home/Home';

const HomePromise = import('./containers/Home/Home');
const Home = React.lazy(() => HomePromise);

class App extends Component {

  constructor(props) {
    super(props);
    this.iResolution = {
      w: window.innerWidth,
      h: window.innerHeight
    }
  }

  state = {
    isResized: false,
    isLoading: true
  }

  componentDidMount() {
    this.isResized = false;
    if (!isMobile) window.addEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate() {
    // this.setState({ isMounted: true });
    // console.log('lol' + performance.now())
  }

  resizeHandler = () => {
    const threshold = 60;
    if (Math.abs(this.iResolution.w - window.innerWidth) > threshold || Math.abs(this.iResolution.h - window.innerHeight) > threshold) {
      const resized = this.state.isResized;
      if (!resized) this.setState({ isResized: true })
    } else {
      const resized = this.state.isResized;
      if (resized) this.setState({ isResized: false })
    }
  }

  setLoaded = () => {
    this.setState({ isLoading: false });
    // window.location.reload();
  }

  render() {
    const content = !this.state.isResized ? <Home /> : <Bsod />;

    return (
      <BrowserRouter>
        <React.Suspense fallback={<Welcome />}>
          {/* <Switch> */}
          {/* {!this.state.isLoading ? <Route render={() => content} /> : null} */}
          {/* <Route render={() => content} /> */}
          {/* <Bsod /> */}
          {/* <Home /> */}
          {content}
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
