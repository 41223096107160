import React, { useEffect } from 'react';

import classes from './BSOD.module.css';
import gsap from 'gsap/gsap-core';

const BSOD = (props) => {

    useEffect(() => {
        gsap.to("#bsodContent", { opacity: 0.0, y: 100, duration: 0.0, inCmplete:() => {
            gsap.to("#bsodContent", {opacity: 1.0, y: 0, duration: 1.0, stagger: 0.25 });
        }})
    }, []);

    const reloadPage = () => {
        window.location.reload();
    }

    return (
        <div id="konami" className={classes.Background}>
            <p id="bsodContent" className={classes.SadFace}>| (• ◡•)|</p>
            <div className={classes.TextContainer}>
                <p id="bsodContent" className={classes.Text}>{"The website got afraid of the sudden movement. But hey, don't let your brain dial your fear volume up. We can try again, ok?"}</p>
            </div>
            <p id="bsodContent" className={classes.ReloadLink}>Just click <span style={{borderBottom: '1px solid white'}} onClick={reloadPage}>here</span> to give it another try</p>
        </div>
    )
}

export default BSOD;